@use "./styles/partials/variables" as *;
@use "./styles/partials/mixins" as *;

* {
  margin: 0;
  padding: 0;
  font-family: $font-stack;
}

html{scroll-behavior:smooth}

body {
  background-color: rgb(134 172 232 / 13%);
}

h1 {
  font-size: 2rem;
  font-weight: 100;

  @include tablet {
    font-size: 2.75rem;
  }
}

h2 {
  font-size: 1.25rem;
  font-weight: 500;

}

p {
  font-size: 1rem;
  font-weight: 100;
  line-height: 1.5rem;

  @include tablet {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

a {
  font-size: 1.25rem;
  font-weight: 100;
  line-height: 2rem;
  text-decoration: none;
  color: white;

  &.anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
  }
}

li {
  font-size: 1.125rem;
  font-weight: 100;
  line-height: 1.75rem;;
}

span {
  font-weight: 100;
}