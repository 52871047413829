@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.designs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem auto;
    max-width: 30rem;
    padding: 1rem;

    @include tablet {
        margin: 7rem auto;
        max-width: none;
    }

    &__title {
        margin-bottom: 2rem;
        border-bottom: 1px solid #434BE7;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 1rem;

        @include tablet {
            width: 44rem;
        }

        @include desktop {
            width: 50rem;
        }
    }

    &__container {
        width: 100%;

        @include tablet {
            width: 44rem;
        }
        
        @include desktop {
            width: 50rem;
        }
    }

    &__title {
        margin-bottom: 2rem;
        border-bottom: 1px solid #434BE7;
        width: 23rem;
        display: flex;
        justify-content: center;
        padding-bottom: 1rem;
        width: 100%;
    }

    &__carousel {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        background-color: rgba(29, 110, 240, 0.1254901961);
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        gap: 2rem;
        height: 34rem;
        padding: 2rem;

        @include tablet {
            flex-direction: row;
            height: 22rem;
        }
    }

    &__images {
        display: flex;
        gap:1rem;
    }

    &__logo {
        max-height: 4rem;
        margin-left: -1.25rem;
    }

    &__image {
        width:9rem;

        @include tablet {
            width: auto;
        }
    }

    &__links {
        margin-top:1rem;
        display:flex;
        align-items: center;
        font-size:1.125rem;
    }

    &__link {
        margin-left: 1rem;
        display: flex;
    }

}