@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.mobile-menu {
    transition: all .5s ease;

    @include tablet {
        visibility:hidden;
    }

    &.active {
        visibility: visible;
        right: 0;
        display: flex;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        z-index: 997;
        flex-direction: column;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.8);
        
        @include tablet {
            visibility:hidden;
        }
    }

    &__links {
        display: flex;
        height: 100%;
        flex-direction: column;
        margin-top: 12rem;

        @include tablet {
            display: none;
        }
    }

    &__list-item {
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    &__link {
        font-size: 1.75rem;
        position: relative;
        transition: all .3s ease-in-out;
        text-shadow: 1px 1px 2px rgb(0 0 0 / 50%);

        &:hover {
            color: #8288f1;
        }
    }
}