@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.work {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem auto;
    max-width: 30rem;
    padding: 1rem;

    @include tablet {
        margin: 7rem auto;
        max-width: none;
    }

    &__title {
        margin-bottom: 2rem;
        border-bottom: 1px solid #434BE7;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 1rem;

        @include tablet {
            width: 44rem;
        }

        @include desktop {
            width: 50rem;
        }
    }
    @include tablet {
        width: 44rem;
    }
    
    @include desktop {
        width: 50rem;
    }

    &__item {
        display: flex;
        flex-direction: column;
        background-color: rgba(29, 110, 240, 0.1254901961);
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        padding: 2rem;

        @include tablet {
            flex-direction: row;
            align-items: center;
        }
    }

    &__container {
        display: flex;
        width: 100%;

        @include tablet {
            width: 44rem;
            height: 28rem;
        }

        @include desktop {
            width: 50rem;
        }
    }

    &__project-title {
        margin: .75rem 0 .5rem 0;
        text-align: left;

        @include tablet {
            margin: 0 0 .5rem 0;
            text-align: left;
        }
    }

    &__project-text {

        @include tablet {
            margin-bottom: .5rem;
        }
    }

    &__image {
        width: 10rem;
        border-radius: 4px;

        @include tablet {
            margin-right: 2rem;
        }

        @include desktop {
            margin-left: 2rem;
        }
    }

    &__tech-list {
        list-style: none;
        display: flex;
        gap: .5rem;
        row-gap: 0;
        margin: .5rem 0;
        color: #434BE7;
        flex-wrap: wrap;

        @include tablet {
            margin: 1rem 0 1rem 0;
        }
    }

    &__tech {
        font-size: 1rem;

        @include tablet {
            font-size: 1.125rem; 
        }
    }

    &__links {
        display: flex;
        align-items: center;
    }

    &__link {
        height: 100%;
    }

    &__link-icon {
        width: 1.75rem;
        margin-right: 1rem;
        transition: all .1s ease-in-out;

        &:hover {
            transform: translateY(-2.5px);
        }

        @include tablet {
            height: 2rem;
        }

        &--github {
            height: 1.7rem;
        }
    }
}
