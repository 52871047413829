@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5rem 0 2rem 0;

    &__list {
        display: flex;
        list-style-type: none;
        gap: 2rem;
    }

    &__icons {
        padding: 2rem 0;
        height: 2.5rem;
        transition: all .1s ease-in-out;

        &:hover {
            transform: translateY(-1px);
            opacity:80%;
        }
    }

    &__text {
        margin: 2rem 0 1rem 0;
        width: 24rem;
        text-align: center;

        @include tablet {
            width: 32rem;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include tablet {
            flex-direction: row;
        }
    }

    &__footer-text {
        font-size: .75rem;
        margin-bottom: 1rem;

        @include tablet {
            font-size: 1rem;
        }
    }

    &__up-arrow {
        @include chevron;

        @include tablet {
            right: 5rem;
            position: absolute;
        }
    }
}