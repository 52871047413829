@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.about {
    margin: 0 1rem 3rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    @include tablet {
        flex-direction: row;
    }

    @include desktop {
        gap: 8rem;
    }

    &__headshot {
        height: 15rem;
        width: 15rem;

        @include tablet {
            height: 18rem;
            width: 18rem;
        }

        @include desktop {
            height: 20rem;
            width: 20rem;
        }
    }

    &__description {
        flex-direction: column;
        justify-content: center;
        display: flex;
        align-items: center;
        background-color: rgba(29, 110, 240, 0.1254901961);
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        padding: 2rem;
        width: 100%;
        height: 100%;
        max-width: 20rem;

        @include tablet {
            max-width: 40rem;
        }
    }

    &__title {
        text-align: center;
    }

    &__text {
        padding: 1rem 0 0 0;
        text-align: center;

        @include tablet {
            padding: 2rem 0;
        }
    }

    &__knowledge {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        @include tablet {
            flex-direction: row;
            gap: 3rem;
        }
    }
}

.technologies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20rem;
    padding: 2rem 2rem;
    margin-top: 3rem;
    background-color: #1d6ef020;
    border-radius: 4px;

    &__title {
        padding-bottom: 1.5rem;
    }

    &__list {
        width: 20rem;
        display: flex;
        justify-content: center;
        gap: 5rem;
    }

    &__type {
        font-weight: 500;
        margin-left: -1.5rem;
        margin-bottom: 0.5rem;
    }
}

.learning {
        width: 12rem;
        margin-top: 1rem;
        background-color: #1d6ef020;
        padding: 2rem;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    &__title {
        margin-bottom: 1rem;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
        row-gap: .5rem;
    }
}

.arrow-down {
    position: absolute;
    right: 47%;
    top: 37rem;
    @include chevron;

    @include tablet {
        top: 35rem; 
    }
}