@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(#434BE7, #b39ce3);
    height: 40rem;
    width: 100%;
    clip-path:  polygon(100% 0%, 0% 0% , 0% 84.96%, 1% 84.95%, 2% 84.91%, 3% 84.84%, 4% 84.75%, 5% 84.63%, 6% 84.48%, 7% 84.31%, 8% 84.11%, 9% 83.89%, 10% 83.65%, 11% 83.38%, 12% 83.09%, 13% 82.78%, 14% 82.45%, 15% 82.11%, 16% 81.74%, 17% 81.36%, 18% 80.96%, 19% 80.55%, 20% 80.12%, 21% 79.69%, 22% 79.24%, 23% 78.79%, 24% 78.33%, 25% 77.86%, 26% 77.39%, 27% 76.92%, 28% 76.45%, 29% 75.98%, 30% 75.51%, 31% 75.04%, 32% 74.58%, 33% 74.13%, 34% 73.69%, 35% 73.25%, 36% 72.83%, 37% 72.41%, 38% 72.02%, 39% 71.64%, 40% 71.27%, 41% 70.92%, 42% 70.59%, 43% 70.28%, 44% 70.00%, 45% 69.73%, 46% 69.49%, 47% 69.27%, 48% 69.07%, 49% 68.90%, 50% 68.75%, 51% 68.63%, 52% 68.54%, 53% 68.47%, 54% 68.43%, 55% 68.42%, 56% 68.44%, 57% 68.48%, 58% 68.54%, 59% 68.64%, 60% 68.76%, 61% 68.91%, 62% 69.08%, 63% 69.27%, 64% 69.50%, 65% 69.74%, 66% 70.01%, 67% 70.30%, 68% 70.61%, 69% 70.94%, 70% 71.29%, 71% 71.65%, 72% 72.03%, 73% 72.43%, 74% 72.84%, 75% 73.27%, 76% 73.70%, 77% 74.15%, 78% 74.60%, 79% 75.06%, 80% 75.53%, 81% 76.00%, 82% 76.47%, 83% 76.94%, 84% 77.42%, 85% 77.88%, 86% 78.35%, 87% 78.81%, 88% 79.26%, 89% 79.71%, 90% 80.14%, 91% 80.57%, 92% 80.98%, 93% 81.37%, 94% 81.76%, 95% 82.12%, 96% 82.47%, 97% 82.80%, 98% 83.11%, 99% 83.39%, 100% 83.66%);

    @include tablet {
        height: 40rem;
    }

    &__text {
        margin-top: -15rem;
        padding: 6rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        color: white;

        @include tablet {
            padding: 6rem 0;
            margin-top: -20rem;
        }
    }

    &__name {
        font-weight: 700;
        font-size: 3.25rem;
        color: white;
        white-space: nowrap;
        overflow: hidden;  

        @include tablet {
            font-size: 5rem;
        }
    }

    &__description {

        @include tablet {
            width: 40rem;
        }
    }
}