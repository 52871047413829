@use "./variables" as *;

// Breakpoints
@mixin tablet {
    @media screen and (min-width: $tablet-breakpoint) {     
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin chevron {
    height: 2rem;
    cursor: pointer;
    padding:.5rem;
    border-radius:6px;
    transition: all 0.1s ease-in-out;

    &:hover {
        transform: translateY(-2.5px);
        cursor: pointer;
    }
}